<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card-header class="mb-3">
      <b-row>
        <b-col> <i class="icon-menu mr-1"></i>Manual Bookings</b-col>
      </b-row>
    </b-card-header>
    <b-card>
      <div>
        <b-tabs v-model="tabIndex" content-class="mt-3">
          <b-tab title="Transaction Failures" active>
            <b-row>
              <b-col sm="4">
                <b-form-group class="ml-3">
                  <b-row>
                    <b-form-radio
                      v-model="bookingsData.radioOption"
                      value="byDate"
                      class="mr-2"
                      ><strong>Search by Date</strong></b-form-radio
                    >
                    <b-form-radio
                      v-model="bookingsData.radioOption"
                      value="byOrderID"
                      class="mr-1"
                      ><strong>Search by Order ID</strong></b-form-radio
                    >
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form
              class="mb-3"
              v-if="bookingsData.radioOption == 'byDate'"
              v-on:submit.prevent.self="submit"
            >
              <b-row>
                <b-col sm="4">
                  <label for="from_date">From Date:</label>
                  <b-form-input
                    type="date"
                    id="from_date"
                    v-model="bookingsData.fromDate"
                  ></b-form-input>
                </b-col>
                <b-col sm="4">
                  <label for="to_date">To Date:</label>
                  <b-form-input
                    type="date"
                    id="to_date"
                    v-model="bookingsData.toDate"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-col sm="2" class="px-0">
                <b-button type="submit" variant="success" class="mt-2" block
                  >Submit</b-button
                >
              </b-col>
            </b-form>
            <b-form class="mb-3" v-else v-on:submit.prevent.self="submit">
              <b-row>
                <b-col>
                  <b-form-group label="Enter Booking ID:">
                    <b-form-input
                      type="text"
                      v-model="bookingsData.bookingID"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="2">
                  <b-button type="submit" variant="success" class="mt-2" block
                    >Submit</b-button
                  >
                </b-col>
              </b-row>
            </b-form>
            <hr />
            <div>
              <v-client-table
                :columns="columns1"
                :data="data1"
                :options="options1"
              ></v-client-table>
            </div>
          </b-tab>
          <b-tab title="Book New Transactions">
            <b-row>
              <b-col lg="3">
                <label for="malls" class="mb-1"><strong>Malls</strong></label>
                <b-form-select
                  v-model="bookingsData.selectedMalls"
                  id="malls"
                  :plain="true"
                  required
                  value-field="Malls"
                  text-field="Malls"
                  :options="staticData.mall"
                >
                  <template slot="first">
                    <option :value="'0'" disabled>-- Select Malls --</option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col lg="3">
                <label for="show_date" class="mb-1"
                  ><strong>Show Date</strong></label
                >
                <b-form-input
                  id="show_date"
                  type="date"
                  v-model="bookingsData.showDate"
                  placeholder="dd/mm/yyyy"
                ></b-form-input>
              </b-col>
              <b-col lg="3">
                <label for="screens" class="mb-1"
                  ><strong>Screens</strong></label
                >
                <b-form-select
                  v-model="bookingsData.selectedScreens"
                  id="screens"
                  :plain="true"
                  required
                  value-field="Screens"
                  text-field="Screens"
                  :options="staticData.screens"
                >
                  <template slot="first">
                    <option :value="'0'" disabled>-- Select Screens --</option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col lg="3">
                <label for="show_time" class="mb-1"
                  ><strong>Show Time</strong></label
                >
                <b-form-select
                  v-model="bookingsData.showTime"
                  id="show_detail"
                  :plain="true"
                  required
                  value-field="ShowTime"
                  text-field="ShowTime"
                  :options="staticData.showTime"
                >
                  <template slot="first">
                    <option :value="'0'" disabled>-- Show Tiime --</option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col lg="3">
                <b-button type="submit" variant="success" class="mt-2" block
                  >Search</b-button
                >
              </b-col>
              <b-col lg="3">
                <b-button
                  type="submit"
                  variant="success"
                  class="mt-2"
                  block
                  @click="resetForm"
                  >Reset</b-button
                >
              </b-col>
            </b-row>
            <div class="mt-2">
              <v-client-table
                :columns="columns2"
                :data="data2"
                :options="options2"
              >
                <div slot="Action">
                  <b-button
                    variant="success"
                    class="mt-2"
                    block
                    @click="$router.push('manual-bookings/seat-bookings')"
                    >Book Now</b-button
                  >
                </div>
              </v-client-table>
            </div>
          </b-tab>
          <hr />
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import MasterService from "@/services/MasterService";
import OrganizationService from "@/services/OrganizationService";
import ReportService from "@/services/ReportService";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";

// jspdf-autotable
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";

export default {
  name: "ManualBookings",
  components: {
    Event,
    cSwitch,
  },
  watch: {},
  data: function () {
    return {
      bookingsData: {
        fromDate: "",
        toDate: "",
        radioOption: "byDate",
        bookingID: "",
        selectedMalls: "",
        showDate: "",
        selectedScreens: "",
        showTime: "",
      },
      tabIndex: 0,
      staticData: {
        mall: ["Mall 1", "Mall 2"],
        screens: ["Screen 1", "Screen 2"],
        showTime: ["11:00 AM", "12:00 AM"],
        data: [
          {
            id: 1,
            Mall: "Mall 1",
            Screen: "Screen 1",
            Movie: "Movie 1",
            ShowTime: "12/05/2022",
            ShowDate: "22/05/2022",
            Status: true,
          },
          {
            id: 2,
            Mall: "Mall 2",
            Screen: "Screen 2",
            Movie: "Movie 2",
            ShowTime: "11/05/2022",
            ShowDate: "25/05/2022",
            Status: false,
          },
        ],
      },
      //Transaction Failures
      columns1: [
        "BookingID",
        "OrderID",
        "Cinema",
        "Movies",
        "Screens",
        "SeatsInfo",
        "ShowDetails",
        "BankStatus",
        "TransactionID",
        "TransactionResult",
        "CreatedDate",
        "UpdatedDate",
        "TransactionStarttime",
        "TransactionEndtime",
        "TimeDifference",
        "Source",
      ],
      data1: [],
      options1: {
        headings: {
          BookingID: "Booking ID",
          OrderID: "Order ID",
          Cinema: "Cinema",
          Movies: "Movie Name",
          Screens: "Screen",
          SeatsInfo: "Seats Info",
          ShowDetails: "Show Details",
          BankStatus: "Bank Status",

          TransactionID: "Transaction ID",
          TransactionResult: "Transaction Result",
          CreatedDate: "Created Date",
          UpdatedDate: "Updated Date",
          TransactionStarttime: "Transaction Starttime",
          TransactionEndtime: "Transaction Endtime",
          TimeDifference: "Time Difference",
          Source: "Soruce",
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      //Transaction Failures
      //Book New Transcation
      columns2: ["Screens", "Movies", "ShowDate", "ShowTime", "Action"],
      data2: [
        {
          id: 1,
          Mall: "Mall 1",
          Screen: "Screen 1",
          Movie: "Movie 1",
          ShowTime: "12/05/2022",
          ShowDate: "22/05/2022",
          Status: true,
        },
        {
          id: 2,
          Mall: "Mall 2",
          Screen: "Screen 2",
          Movie: "Movie 2",
          ShowTime: "11/05/2022",
          ShowDate: "25/05/2022",
          Status: false,
        },
      ],
      options2: {
        headings: {
          Screens: "Screen",
          Movies: "Movies",
          ShowDate: "Show Date",
          ShowTime: "Show Time",
          Status: "Status",
          Action: "Action",
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      //Book New Transcation

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  beforeMount() {},
  mounted() {},
  methods: {
    // Saif
    resetForm() {
      Object.keys(this.bookingsData).forEach(
        (key) => (this.bookingsData[key] = "")
      );
    },
  },
};
</script>

<style></style>
